import React, { useState, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';
import { uniqBy, filter, get, lowerCase } from '../../lib/nodash';

import useMobile from '../useMobile';
import shopifyGetGraphQlId from '../../lib/shopifyGetGraphQlId';
import ListItemThumbnailLineItem from './ListItemThumbnailLineItem';
import ListItemLineItemTitle from './ListItemLineItemTitle';

const ListLineItemThumbnails = ({ items = [], textSize = 'medium' }) => {
  const isMobile = useMobile();
  const [activeItem, setActiveItem] = useState(items[0]);

  const uniqItems = useMemo(() => uniqBy('title', items), [items]);

  const nonSampleItems = useMemo(
    () =>
      filter(
        (x) =>
          (get('title', x) || get('name', x) || '').indexOf('- Sample') < 0,
        uniqItems
      ),
    [uniqItems]
  );

  const nonDonationItems = useMemo(
    () =>
      filter(
        (x) =>
          (
            lowerCase(get('title', x)) ||
            lowerCase(get('name', x)) ||
            ''
          ).indexOf('donation') < 0,
        nonSampleItems
      ),
    [nonSampleItems]
  );

  if (!Array.isArray(items)) {
    return null;
  }

  return (
    <>
      {!isMobile && (
        <>
          <Text size={textSize} transform="uppercase">
            {activeItem ? activeItem.title : ' '}
          </Text>
          <Box
            direction="row"
            flex="grow"
            wrap={true}
            margin={{ vertical: '0.3rem' }}
            gap="4px"
          >
            {nonDonationItems.map((x, i) => {
              return (
                <ListItemThumbnailLineItem
                  onHover={setActiveItem}
                  itemData={x}
                  key={x.variant_id || x.title || i}
                  productId={shopifyGetGraphQlId('Product', x.product_id)}
                  isActive={activeItem ? x.title === activeItem.title : false}
                />
              );
            })}
          </Box>
        </>
      )}
      {isMobile && (
        <Box
          direction="row"
          wrap={true}
          gap="0.2rem"
          margin={{ top: '0.3rem' }}
        >
          {items.map((x, i, arr) => {
            return (
              <ListItemLineItemTitle
                isLast={i === arr.length - 1}
                item={x}
                key={x.variant_id || i}
              />
            );
          })}
        </Box>
      )}
    </>
  );
};

ListLineItemThumbnails.propTypes = {
  items: PropTypes.array,
  textSize: PropTypes.string,
};

export default memo(ListLineItemThumbnails);
