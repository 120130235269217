import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'grommet';
import styled from 'styled-components';
import { decode } from 'he';

import { get } from '../../lib/nodash';
import { getProductPath } from '../../lib/product';
import Loading from '../Loading';
import SmartLink from '../SmartLink';

const StyledAnchor = styled(SmartLink)`
  &:hover {
    text-decoration: underline;
  }
`;

const ListItemLineItemTitle = ({ loading, item, isLast }) => {
  const productId = get('product.id', item) || get('product_id', item);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <StyledAnchor href={getProductPath(item)}>
          <Text key={productId} size="small" className="text-small">
            {decode(item.title)}
            {isLast ? '' : ', '}
          </Text>
        </StyledAnchor>
      )}
    </>
  );
};

ListItemLineItemTitle.propTypes = {
  loading: PropTypes.bool,
  item: PropTypes.object,
  isLast: PropTypes.bool,
};

export default ListItemLineItemTitle;
