import React from 'react';
import PropTypes from 'prop-types';
import IconStar from '../Icons/IconStar';
import styled from 'styled-components';

const StyledStar = styled(IconStar)`
  stroke: none;
  ${(p) => {
    return (
      p.decimal > 0 &&
      `
      *[fill*="#"], *[FILL*="#"] {
        fill: url(#gradient-${p.decimal})
      }
    `
    );
  }}
`;

const RatingStar = ({ active, size = '20px', decimal, color = 'black' }) => (
  <StyledStar active={active} decimal={decimal} color={color} size={size} />
);

RatingStar.propTypes = {
  size: PropTypes.string,
  decimal: PropTypes.number,
  color: PropTypes.string,
  active: PropTypes.bool,
  handleClick: PropTypes.func,
  value: PropTypes.number,
};

export default RatingStar;
