import React, { useCallback, useState, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Layer, Keyboard } from 'grommet';
import { Close } from 'grommet-icons';

import DisplayReview from './DisplayReview';

const ModalClose = styled(Box)`
  position: absolute;
  right: 0.4em;
  top: 0.4em;
  cursor: pointer;
  z-index: 10;
  height: 28px;
  width: 28px;
`;

const ModalReviewDisplay = ({ review, setViewingModal, index }) => {
  const closeModal = useCallback(() => setViewingModal(null));
  const [activeSlide, setActiveSlide] = useState(index);
  const reviewFiles = review.files;

  return (
    <>
      {review && (
        <Keyboard
          onRight={() => {
            if (
              reviewFiles.length > 1 &&
              activeSlide < reviewFiles.length - 1
            ) {
              setActiveSlide(activeSlide + 1);
            }
          }}
          onLeft={() => {
            if (activeSlide > 0) {
              setActiveSlide(activeSlide - 1);
            }
          }}
        >
          <Layer
            onEsc={closeModal}
            onClickOutside={closeModal}
            margin="medium"
            responsive={false}
          >
            <Box border={{ size: 'small', color: 'black' }} overflow="auto">
              <ModalClose
                background="white"
                justify="center"
                alignContent="center"
                align="center"
                alignSelf="center"
                round="100px"
              >
                <Close size="16px" color="black" onClick={closeModal} />
              </ModalClose>
              <DisplayReview reviewData={review} activeSlide={activeSlide} />
            </Box>
          </Layer>
        </Keyboard>
      )}
    </>
  );
};

ModalReviewDisplay.propTypes = {
  review: PropTypes.object,
  setViewingModal: PropTypes.func,
  index: PropTypes.number,
};

export default memo(ModalReviewDisplay);
