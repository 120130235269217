import React, { memo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box } from 'grommet';

import FeaturedReview from './FeaturedReview';
import deNodify from '../../lib/deNodify';

const FeaturedReviews = (props) => {
  const { featured } = useStaticQuery(graphql`
    query FeaturedReviews {
      featured: allStrapiFeaturedReviews {
        edges {
          node {
            id
            title
            upvote_count
            downvote_count
            createdAt
            comments
            comments_published
            rating
            rating_published
            order_id
            order_created_at
            files {
              uploadURL
            }
            files_published
            customer_id
            files_published
            recommended
            first_name
            last_name
            city
            state
            location
            repeat_customer
            excerpt
            featured_title
          }
        }
      }
    }
  `);
  return (
    <Box {...props}>
      {deNodify(featured).map((x, i) => (
        <FeaturedReview key={x.id} reviewData={x} altLayout={i % 2 !== 0} />
      ))}
    </Box>
  );
};

export default memo(FeaturedReviews);
