import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { get } from '../../lib/nodash';

import ImgixImage from '../ImgixImage';

const ListReviewUgc = ({ files, handleClick }) => {
  return (
    <Box direction="row" gap="xsmall" wrap={true}>
      {files.map((x, i) => (
        <Box
          key={get('uploadURL', x)}
          onClick={() => handleClick(i)}
          width="100px"
          height="100px"
        >
          <ImgixImage
            src={get('uploadURL', x)}
            options={{ w: 100, h: 100, fit: 'crop' }}
          />
        </Box>
      ))}
    </Box>
  );
};

ListReviewUgc.propTypes = {
  files: PropTypes.array,
  handleClick: PropTypes.func,
};

export default ListReviewUgc;
