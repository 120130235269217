import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'grommet';

import { getClientForUrl, getKeyFromUrl } from '../../lib/imgix';

const Container = styled(Box)`
  min-height: 300px;
  background: url(${(p) => p.background});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    min-height: 500px;
  }

  @media only screen and (min-width: 375px) and (max-width: 667px) {
    min-height: 300px;
  }

  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    min-height: 500px;
  }

  @media only screen and (min-width: 1600px) {
    min-height: 700px;
  }
`;

const FeaturedUgc = ({ file }) => {
  const { uploadURL } = file;
  const name = getKeyFromUrl(uploadURL);
  const config = {
    auto: 'compress',
    fit: 'crop',
    w: 1000,
    q: 80,
  };
  const client = getClientForUrl(uploadURL);
  const srcset = client.buildSrcSet(name, config);
  const url = client.buildURL(name, config);
  return <Container fill background={url} srcset={srcset}></Container>;
};

FeaturedUgc.propTypes = {
  file: PropTypes.object,
};

export default FeaturedUgc;
