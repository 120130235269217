import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Paragraph, Text } from 'grommet';
import { head } from '../../lib/nodash';
import styled from 'styled-components';

import StarRating from './StarRating';
import FeaturedUgc from './FeaturedUgc';

const Container = styled(Box)`
  ${(p) =>
    p.altLayout ? 'flex-direction: row-reverse;' : 'flex-direction: row;'}
  @media only screen ${(p) =>
    p.theme.global.breakpoints.small.value &&
    `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    flex-direction: column;
  }
`;

const FeaturedReview = ({ reviewData = {}, altLayout }) => {
  const {
    rating,
    title,
    featured_title,
    excerpt,
    first_name,
    last_name,
    city,
    state,
    files,
  } = reviewData;
  const customerName = `${first_name} ${last_name.substring(0, 1)}`;
  const featuredUgc = files && files[0] ? files[0] : null;

  return (
    <Container altLayout={altLayout} height={{ min: '400px' }}>
      <Box width="100%" background={'transparent'}>
        {featuredUgc && <FeaturedUgc file={featuredUgc} />}
      </Box>
      <Box
        width="100%"
        height={'auto'}
        background={'transparent'}
        pad={{ vertical: 'medium' }}
      >
        <Box pad="medium" justify="stretch" flex="grow">
          <Box pad={{ horizontal: '20px' }} flex="grow">
            <StarRating pad={{ vertical: 'small' }} rating={rating} />
            <Heading
              style={{ textTransform: 'none' }}
              margin={{ vertical: 'small' }}
              size="small"
              level={2}
            >
              &ldquo;{featured_title || title}&rdquo;
            </Heading>
            <Paragraph margin={{ top: 'small' }}>{excerpt}</Paragraph>
            <Box>
              <Text size="small" margin={{ bottom: 'none', top: 'small' }}>
                {customerName}
              </Text>
              <Text size="small" className="text-small" margin="none">
                {city}, {state}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

FeaturedReview.propTypes = {
  reviewData: PropTypes.object,
  altLayout: PropTypes.bool,
};

export default memo(FeaturedReview);
