import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Paragraph, Text } from 'grommet';
import { format, parseISO } from 'date-fns';
import { get, head, reject, filter } from '../../lib/nodash';
import styled from 'styled-components';

import { withoutSamples, withoutDonations } from '../../lib/product';
import StarRating from './StarRating';
import ContentSlider from '../ContentSlider';
import ImgixImage from '../ImgixImage';
import ListLineItemThumbnails from './ListLineItemThumbnails';
import useSwiperThumbnailPagination from '../useSwiperThumbnailPagination';

const MetaContainer = styled(Box)`
  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    width: 100%;
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
`;

const SliderContainer = styled(Box)``;

const Content = styled(Box)`
  display: grid;
  grid-template-rows: 60vh auto;
  grid-template-columns: calc(100vw - 45px);
  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (min-width: ${p.theme.global.breakpoints.small.value}px)`} {
    grid-template-columns: 60vw calc(
        40vw - ${(p) => p.theme.global.edgeSize.medium} -
          ${(p) => p.theme.global.edgeSize.medium} -
          ${(p) => p.theme.global.edgeSize.small}
      );
    grid-template-rows: auto;
  }
`;

const DisplayReview = ({ reviewData, activeSlide }) => {
  const {
    rating,
    title,
    comments,
    first_name,
    last_name,
    city,
    state,
    files,
    createdAt,
    recommended,
    project,
    line_items,
  } = reviewData;

  const products = withoutDonations(
    withoutSamples(reject({ custom: true }, line_items))
  );

  const reviewFiles = files;
  const paintProducts = filter(
    (x) => get('variant_title', x).indexOf('Gallon') > -1,
    products
  );
  const imageDescription = useMemo(
    () =>
      `${paintProducts.map((x) => get('title', x)).join(', ')} paint color${
        paintProducts.length > 1 ? 's' : ''
      } by Backdrop. ${project ? project : ''}`
  );

  const pagination = useSwiperThumbnailPagination(
    reviewFiles.map((x) => x.uploadURL)
  );

  return (
    <Content overflow="hidden" direction="row-responsive" height="100%">
      <Box width="100%" height="100%">
        <SliderContainer className="slider-ugc-container" background="#000">
          <ContentSlider
            activeSlide={activeSlide}
            swiperStyle={{
              width: '100%',
              height: '100%',
            }}
            params={{
              centeredSlides: false,
              slidesPerView: 1,
              autoHeight: false,
              watchOverflow: true,
              pagination,
            }}
          >
            {reviewFiles.map((x) => {
              return (
                <ImgixImage
                  height="100%"
                  key={get('id', x)}
                  src={get('uploadURL', x)}
                  options={{
                    q: 80,
                    auto: 'compress,format',
                    w: 600,
                  }}
                  alt={imageDescription}
                />
              );
            })}
          </ContentSlider>
        </SliderContainer>
      </Box>
      <MetaContainer
        background="#fff"
        pad="medium"
        overflow="auto"
        flex={{ grow: 1, shrink: 1 }}
        width="medium"
        gap="medium"
        alignSelf="stretch"
        align="stretch"
      >
        <div>
          <Box gap="medium" margin={{ top: 'medium' }}>
            <Box direction="column" flex="grow">
              <Box flex="grow">
                <Heading
                  level={4}
                  size="small"
                  margin={{ bottom: 'none', top: 'small' }}
                  style={{ textTransform: 'none' }}
                >
                  {first_name} {head(last_name)}
                </Heading>
                <Text size="small">
                  {format(parseISO(createdAt), 'MM/dd/yyyy')}
                </Text>
                <Text size="small" margin="none">
                  {city}, {state}
                </Text>
              </Box>
              <StarRating
                starSize="16px"
                gap="3px"
                pad={{ vertical: 'small' }}
                rating={rating}
              />
            </Box>
            <Box>
              <Heading level={4} size="small" style={{ textTransform: 'none' }}>
                {title}
              </Heading>
              <Paragraph size="small">{comments}</Paragraph>
            </Box>
            <Text size="small">
              <Text size="small" weight={500}>
                Bottom Line:
              </Text>{' '}
              <Text size="small">
                {recommended
                  ? 'Yes, I would recommend this to a friend'
                  : 'No, I would not recommend this to a friend'}
              </Text>
            </Text>
            <Box direction="row" justify="stretch" width="100%" gap="xsmall">
              <Box flex={{ grow: 1, shrink: 1 }} align="stretch" basis="100%">
                <div>
                  <Heading level={4} size="small">
                    Purchased
                  </Heading>
                  <ListLineItemThumbnails items={products} textSize="small" />
                </div>
              </Box>
              <Box flex={{ grow: 1, shrink: 1 }} align="stretch" basis="100%">
                <div>
                  <Heading
                    level={4}
                    size="small"
                    style={{ textTransform: 'none' }}
                  >
                    Painted Space
                  </Heading>
                  <Text size="small">{project}</Text>
                </div>
              </Box>
            </Box>
          </Box>
        </div>
      </MetaContainer>
    </Content>
  );
};

DisplayReview.propTypes = {
  data: PropTypes.object,
  reviewData: PropTypes.object,
  activeSlide: PropTypes.number,
};

export default memo(DisplayReview);
