import { createSelector } from 'reselect';
import { reduce } from '../../lib/nodash';

const selectShopifyProductsByShopifyId = createSelector(
  (state) => state.product.shopifyProducts,
  (products) => {
    return reduce(
      (mem, product) => {
        mem[product.shopifyId] = product;
        return mem;
      },
      {},
      products
    );
  }
);

export default selectShopifyProductsByShopifyId;
