import gql from 'graphql-tag';

export const UPVOTE_REVIEW = gql`
  mutation UpvoteReview($id: ID!) {
    upvoteReview(input: { id: $id }) {
      id
      upvote_count
    }
  }
`;

export const DOWNVOTE_REVIEW = gql`
  mutation DownvoteReview($id: ID!) {
    downvoteReview(input: { id: $id }) {
      id
      downvote_count
    }
  }
`;

export const TOTAL_REVIEW_SCORE = gql`
  query ReviewsScore {
    totalReviewScore {
      rating
    }
  }
`;

export const NON_FEATURED_REVIEWS = gql`
  query NonFeaturedReviews($limit: Int!, $start: Int!) {
    total: reviewsConnection(
      where: { is_approved: true, is_featured_ne: true }
    ) {
      aggregate {
        count
      }
    }
    reviews: reviews(
      where: { is_approved: true, is_featured_ne: true }
      sort: "createdAt:desc"
      limit: $limit
      start: $start
    ) {
      id
      title
      line_items
      upvote_count
      downvote_count
      createdAt
      comments
      comments_published
      rating
      rating_published
      order_id
      order_created_at
      files
      files_published
      customer_id
      project
      files_published
      files
      recommended
      first_name
      last_name
      city
      state
      location
      repeat_customer
    }
  }
`;
