import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'grommet';
import SmartLink from '../SmartLink';

import { getProductPath } from '../../lib/product';
import makeSelectProductById from '../../state/product/makeSelectShopifyProductByShopifyId';

const boxSize = '50px';

const Container = styled(Box)`
  cursor: pointer;
`;

const StyledImage = styled.img`
  width: 100%;
  display: block;
`;

const ListItemThumbnailLineItem = ({ itemData, onHover, isActive }) => {
  const { handle } = itemData;
  return (
    <SmartLink to={handle && getProductPath({ handle })}>
      <Container
        width={boxSize}
        height={boxSize}
        background={'transparent'}
        border={{
          color: isActive ? 'black' : 'transparent',
          size: 'xsmall',
          style: 'solid',
          side: 'all',
        }}
        onMouseOver={() => onHover && onHover(itemData)}
      >
        <StyledImage data-src={itemData.image_url} className="lazyload" />
      </Container>
    </SmartLink>
  );
};

ListItemThumbnailLineItem.propTypes = {
  itemData: PropTypes.object,
  isActive: PropTypes.bool,
  onHover: PropTypes.func,
};

export default memo(ListItemThumbnailLineItem);
