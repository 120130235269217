import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

import { get } from '../../lib/nodash';
import { useStoryblokState } from '../../lib/storyblok';
import PageReviews from '../../components/Reviews/PageReviews';
import Seo from '../../components/Seo';
import deNodify from '../../lib/deNodify';
import usePageBodyComponents from '../../components/DynamicPage/usePageBodyComponents';
import { setPaintCollections } from '../../state/product/productSlice';

const Reviews = ({ data, location }) => {
  const { reviews, storyblokEntry, paintCollections } = data;
  const canonicalUrl = data.site.siteMetadata.siteUrl + location.pathname;
  const dispatch = useDispatch();
  const story = useStoryblokState(storyblokEntry);
  const components = usePageBodyComponents(story);
  useEffect(() => {
    dispatch(setPaintCollections(deNodify(paintCollections)));
  }, [paintCollections]);

  return (
    <>
      <Seo
        title={
          get('content.metafields.title', story) ||
          get('title', story) ||
          get('field_title_string', storyblokEntry)
        }
        description={get('content.metafields.description', story)}
        image={get('content.seoImage.filename', story)}
        canonical={canonicalUrl}
      />
      <Box>{components}</Box>
      <PageReviews reviews={deNodify(reviews)} />
    </>
  );
};

Reviews.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default memo(Reviews);

export const query = graphql`
  query ReviewsPageContent {
    total: allStrapiReview {
      totalCount
    }
    reviews: allStrapiReview(
      sort: { fields: createdAt, order: DESC }
      limit: 30
    ) {
      edges {
        node {
          strapiId
          id
          title
          line_items {
            name
            title
            handle
            quantity
            image_url
            variant_title
            price
            gift_card
            total_discount
            admin_graphql_api_id
            product_id
          }
          upvote_count
          downvote_count
          createdAt
          comments
          comments_published
          rating
          rating_published
          order_id
          order_created_at
          files {
            id
            uploadURL
          }
          files_published
          customer_id
          project
          files_published
          recommended
          first_name
          last_name
          city
          state
          location
          repeat_customer
        }
      }
    }
    storyblokEntry(full_slug: { eq: "pages/reviews" }) {
      id
      name
      full_slug
      content
      field_title_string
      internalId
    }
    paintCollections: allShopifyCollection(
      filter: {
        metafields: {
          elemMatch: {
            key: { eq: "type" }
            namespace: { eq: "collection" }
            value: { eq: "Paint" }
          }
        }
      }
      sort: {
        order: [ASC, ASC]
        fields: [
          products___variants___product___handle
          products___variants___id
        ]
      }
    ) {
      edges {
        node {
          id
          title
          handle
          description
          shopifyId
          products {
            shopifyId
            productType
          }
          metafields {
            type
            key
            value
            namespace
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
