import { createSelector } from 'reselect';

import selectShopifyProductsByShopifyId from './selectShopifyProductsByShopifyId';
import extractGid from '../../lib/extractGid';

const makeSelectShopifyProductById = () =>
  createSelector(
    selectShopifyProductsByShopifyId,
    (_, productId) => productId,
    (products, productId) => {
      return products[extractGid(productId)];
    }
  );

export default makeSelectShopifyProductById;
