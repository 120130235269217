import React, { useCallback, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Heading, Paragraph, Text, ResponsiveContext } from 'grommet';
import { Checkmark } from 'grommet-icons';
import { reject, some } from '../../lib/nodash';
import { format, parseISO } from 'date-fns';

import { withoutSamples, withoutDonations } from '../../lib/product';
import StarRating from './StarRating';
import ListLineItemThumbnails from './ListLineItemThumbnails';
import ListReviewUgc from './ListReviewUgc';

const PAINTING_SERVICES_IMAGE_URL =
  'https://cdn.shopify.com/s/files/1/0051/6295/8946/files/PAINTING_SERVICES_ICON-09.png?38141';

const CaseHeading = styled(Heading)`
  text-transform: none;
`;

const IconImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const ListItemReview = ({ reviewData, setViewingModal }) => {
  const {
    title,
    comments,
    comments_published,
    project,
    rating,
    rating_published,
    createdAt,
    first_name,
    last_name,
    city,
    state,
    line_items,
    recommended,
    upvote_count,
    downvote_count,
    files,
    files_published,
    repeat_customer,
    id,
    strapiId,
    response,
  } = reviewData;

  const size = useContext(ResponsiveContext);

  const onUgcClick = useCallback((i) => setViewingModal(strapiId || id, i));

  const products = withoutDonations(
    withoutSamples(reject({ custom: true }, line_items))
  );

  const hasService = some({ custom: true }, line_items);
  const createdAtString = format(parseISO(createdAt), 'MM/dd/yyyy');
  const nameString = `${first_name} ${last_name.substring(0, 1)}`;

  return (
    <>
      <Box direction="row-responsive" pad="20px">
        {size === 'small' && (
          <Box direction="row" pad="medium" align="start">
            <Box direction="column" flex="grow">
              <Text weight={500}>{nameString}</Text>
              <Text>{createdAtString}</Text>
              <Text>
                {city}, {state}
              </Text>
              {repeat_customer && (
                <Box direction="row" align="center">
                  <Text margin={{ right: 'small' }}>Repeat Buyer</Text>
                  <Checkmark color="black" size="small" />
                </Box>
              )}
            </Box>
            {rating_published && <StarRating gap="4px" rating={rating} />}
          </Box>
        )}

        {size !== 'small' && (
          <Box width="medium" pad="medium" flex={{ shrink: 0 }} gap="1.2rem">
            <Box direction="column">
              <Text weight={500}>{nameString}</Text>
              <Text>{createdAtString}</Text>
              <Text>
                {city}, {state}
              </Text>
              {repeat_customer && (
                <Box direction="row" align="center">
                  <Text margin={{ right: 'small' }}>Repeat Buyer</Text>
                  <Checkmark color="black" size="small" />
                </Box>
              )}
            </Box>
            <Box direction="column">
              <Text weight={500}>Purchased</Text>
              <ListLineItemThumbnails items={products} />
            </Box>
            {project && (
              <Box direction="column">
                <Box direction="row" gap="xsmall" align="center">
                  <Text weight={500}>
                    Painted {hasService ? 'by Backdrop' : 'Space'}
                  </Text>
                  <Box width="35px">
                    {hasService && (
                      <IconImg src={PAINTING_SERVICES_IMAGE_URL} />
                    )}
                  </Box>
                </Box>
                <Text>{project}</Text>
              </Box>
            )}
          </Box>
        )}

        <Box pad="medium" gap="small">
          {rating_published && size !== 'small' && (
            <>
              <StarRating size="10px" rating={rating} />
              <Box pad={{ bottom: '0.5rem' }} />
            </>
          )}
          <Box>
            <CaseHeading level={3}>{title}</CaseHeading>
          </Box>

          <Box>{comments_published && <Paragraph>{comments}</Paragraph>}</Box>

          {recommended && (
            <Text>
              <Text weight={500}>Bottom Line:</Text>{' '}
              <Text>&ldquo;Yes, I would recommend this to a friend&rdquo;</Text>
            </Text>
          )}

          {files_published && (
            <Box>
              <ListReviewUgc files={files} handleClick={onUgcClick} />
            </Box>
          )}

          {response && response !== '' && (
            <Box
              align="start"
              margin={{ vertical: 'medium' }}
              pad={{ left: 'large' }}
            >
              <Text weight={500}>Backdrop replied</Text>
              <Box
                pad="1rem"
                background="light-2"
                border={{ side: 'left', size: '0.5em', color: 'light-5' }}
              >
                <Text>{response}</Text>
              </Box>
            </Box>
          )}

          {size === 'small' && (
            <Box
              direction="row-responsive"
              gap="medium"
              margin={{ top: 'medium' }}
            >
              <Box
                direction="column"
                flex={{ shrink: 0 }}
                margin={{ bottom: 'small' }}
              >
                <Text weight={500}>Purchased</Text>
                <ListLineItemThumbnails items={products} />
              </Box>
              {project && (
                <Box
                  direction="column"
                  flex={{ shrink: 0 }}
                  margin={{ bottom: 'small' }}
                >
                  <Box direction="row" gap="xsmall" align="center">
                    <Text weight={500}>
                      Painted {hasService ? 'by Backdrop' : 'Space'}
                    </Text>
                    <Box width="30px">
                      {hasService && (
                        <IconImg src={PAINTING_SERVICES_IMAGE_URL} />
                      )}
                    </Box>
                  </Box>
                  <Text>{project}</Text>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

ListItemReview.propTypes = {
  reviewData: PropTypes.object,
  setViewingModal: PropTypes.func,
};

export default memo(ListItemReview);
