import { useMemo } from 'react';

import { getClientForUrl, getKeyFromUrl } from '../lib/imgix';

const useSwiperThumbnailPagination = (images, options = {}) => {
  const { fit = 'cover' } = options;

  const thumbnailImages = useMemo(() => {
    return images.map((x) => {
      const url =
        typeof x === 'string'
          ? x
          : x.image?.original_src ??
            x.image?.filename ??
            x.image?.originalSrc ??
            x.originalSrc ??
            x.src;
      const client = getClientForUrl(url);
      const imgixSrc = client.buildURL(getKeyFromUrl(url), {
        q: 75,
        w: 50,
        h: 50,
        fit: 'crop',
        crop: 'entropy',
        auto: ['compress', 'format'],
        cs: 'srgb',
      });
      return { image: { ...x, src: imgixSrc }, fit: fit, fill: true };
    });
  }, [images]);

  const pagination = useMemo(() => {
    return thumbnailImages.length > 1
      ? {
          clickable: true,
          className: 'thumbs',
          renderBullet: (index, className) => {
            return thumbnailImages.length
              ? `<div
        class='${className} thumb'>
          <img alt='${thumbnailImages[index]?.image?.altText}' src='${thumbnailImages[index]?.image?.src}' />
        </div>`
              : '';
          },
        }
      : false;
  }, [thumbnailImages]);

  return pagination;
};

export default useSwiperThumbnailPagination;
