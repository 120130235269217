import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { List } from 'grommet';

// import Loading from "../Loading";
import ListItemReview from './ListItemReview';

const ListReviews = ({
  reviews,
  loadPage,
  hasMore,
  setViewingModal,
  ...rest
}) => {
  const loadMore = useCallback((page) => {
    loadPage(page);
  });

  return (
    <List
      data={reviews}
      pageStart={1}
      onMore={loadMore}
      hasMore={hasMore}
      border={{ side: 'bottom', size: 'small' }}
      {...rest}
    >
      {(x) => (
        <ListItemReview
          key={x.id}
          reviewData={x}
          setViewingModal={setViewingModal}
        />
      )}
    </List>
  );
};

ListReviews.propTypes = {
  reviews: PropTypes.array,
  loadPage: PropTypes.func,
  hasMore: PropTypes.bool,
  setViewingModal: PropTypes.func,
};

export default memo(ListReviews);
