import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { get } from '../../lib/nodash';
import { Box, Text, Heading, ThemeContext } from 'grommet';
import StarRating from './StarRating';
import styled from 'styled-components';

import Loading from '../Loading';
import { TOTAL_REVIEW_SCORE } from '../../queries/reviewQueries';

const PlainContainer = styled(Box)`
  align-items: center;

  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    align-items: flex-start;
  }
`;

const OverallReviewScore = ({ plain = false }) => {
  const theme = useContext(ThemeContext);

  const { data, loading } = useQuery(TOTAL_REVIEW_SCORE);

  const rating = get('totalReviewScore.rating', data);
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {plain ? (
        <PlainContainer gap="small" align="center">
          <StarRating rating={rating} />
          <Heading level={4} size="small">
            Overall rating {parseFloat(rating).toFixed(2)}
          </Heading>
        </PlainContainer>
      ) : (
        <Box
          pad={{ vertical: 'small', horizontal: '1rem' }}
          direction="row"
          gap="xsmall"
          align="center"
          flex={{ grow: 0, shrink: 1 }}
          justify="center"
          background="light-2"
          margin="0 auto"
        >
          <Text margin={{ right: '1rem' }}>Overall rating</Text>
          <Text>{parseFloat(rating, 2).toFixed(2)}</Text>
          <Text>|</Text>

          <StarRating
            rating={rating}
            starSize={theme.global.font.size}
            gap="0.3rem"
          />
        </Box>
      )}
    </>
  );
};

OverallReviewScore.propTypes = {
  plain: PropTypes.bool,
};

export default OverallReviewScore;
