import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get, find } from '../../lib/nodash';
import { useLazyQuery } from '@apollo/client';
import { Box } from 'grommet';

import Loading from '../Loading';
import FeaturedReviews from './FeaturedReviews';
import ListReviews from './ListReviews';
import ModalReviewDisplay from './ModalReviewDisplay';
import OverallReviewScore from './OverallReviewScore';
import { NON_FEATURED_REVIEWS } from '../../queries/reviewQueries';

const perPage = 30;

const PageReviews = ({ reviews }) => {
  const [inModal, setInModal] = useState(null);
  const [imageIndex, setImageIndex] = useState(0);
  const [pag, setPag] = useState(1);
  const [
    fetchReviews,
    { data: fetchedReviews, loading, fetchMore },
  ] = useLazyQuery(NON_FEATURED_REVIEWS);
  useEffect(() => {
    if (pag > 1 && fetchMore) {
      fetchMore({
        variables: {
          limit: perPage,
          start: pag * perPage,
        },
      });
    }
  }, [pag]);

  const viewUgc = useCallback((id, i) => {
    setImageIndex(i);
    setInModal(id);
  });

  const allReviews = [...reviews, ...(get('reviews', fetchedReviews) || [])];

  return (
    <Box>
      <FeaturedReviews border={{ side: 'bottom', size: 'small' }} />
      <Box margin={{ vertical: 'large' }}>
        <OverallReviewScore />
      </Box>

      {/* First 30 are rendered server-side */}
      <ListReviews
        reviews={reviews}
        loadPage={() => {
          fetchReviews({
            variables: {
              limit: perPage,
              start:
                (get('reviews', fetchedReviews) || []).length || reviews.length,
            },
          });
        }}
        step={20}
        setViewingModal={viewUgc}
      />
      {loading && <Loading size="large" />}
      {get('reviews', fetchedReviews) && get('reviews', fetchedReviews).length && (
        <ListReviews
          reviews={get('reviews', fetchedReviews)}
          loadPage={() => {
            setPag(pag + 1);
          }}
          hasMore={false}
          setViewingModal={viewUgc}
          step={2}
        />
      )}
      {loading && (
        <Box fill="horizontal" justify="center" align="center">
          <Loading size="large" />
        </Box>
      )}
      {inModal && (
        <ModalReviewDisplay
          review={find(
            (x) => x.strapiId === inModal || x.id === inModal,
            allReviews
          )}
          setViewingModal={viewUgc}
          index={imageIndex}
        />
      )}
    </Box>
  );
};

PageReviews.propTypes = {
  page: PropTypes.object,
  reviews: PropTypes.array,
};

export default PageReviews;
